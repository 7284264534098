<template>
  <v-card class="pa-3">
    <!-- <v-card-title>Nuevo miembro del equipo</v-card-title>
    <v-divider></v-divider> -->
    <v-form>
      <v-row style="border-left: 3px solid var(--v-primary-base);border-top: 3px solid var(--v-primary-base);border-right: 3px solid var(--v-primary-base)">
        <v-col cols="12" class="py-0">
          <h1>DATOS EQUIPO</h1>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            style="width:100%"
            label="Nombre"
            outlined
            hide-details=""
            v-model="unidad.nombre"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            style="width:100%"
            label="Apellidos"
            outlined
            hide-details=""
            v-model="unidad.apellidos"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="py-0">
          <v-text-field
            style="width:100%"
            label="Ap."
            outlined
            hide-details=""
            v-model="unidad.inicialesApellidos"
          ></v-text-field>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-select
            label="Categoria"
            outlined
            hide-details="auto"
            :items="categorias"
            v-model="unidad.idCategoriaEquipo"
            item-text="nombreCategoria"
            item-value="idCategoriaEquipo"
          ></v-select>
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-center pb-0">
          Nº SERVICIOS: {{ unidad.dias_trabajados }}
        </v-col>
        <v-col cols="12" md="4" class="pb-2">
          <v-file-input
            ref="pfp"
            accept="image/*"
            class="d-none"
            prepend-icon=""
            @change="processPfp"
          />
          <v-hover
            v-slot="{ hover }"
            disabled
          >
            <v-sheet @click.stop="addPfp" style="width: 256px;height: 256px;border-top-left-radius: 25%;border-bottom-right-radius: 25%;cursor: pointer;border-radius: 10px;overflow: hidden;">
              <v-icon size="256" v-if="!unidad.pfp">mdi-account</v-icon>
              <v-img
                v-else
                :src="'data:image/jpeg;charset=utf-8;base64,'+unidad.pfp"
                height="256"
                width="256"
              ></v-img>
            </v-sheet>
          </v-hover>
        </v-col>
        <v-col cols="12" md="8" class="pb-0" style="justify-content: space-between;display: flex;flex-wrap: wrap">
          <div style="width:100%;display:flex;flex-direction:column;flex-wrap:wrap;justify-content: space-between;">
            <v-text-field
              label="Prioridad"
              outlined
              hide-details="auto"
              v-model="unidad.prioridad"
            ></v-text-field>
            <v-text-field
              label="Experiencia"
              outlined
              hide-details="auto"
              v-model="unidad.experiencia"
            ></v-text-field>
            <v-text-field
              label="Disponibilidad"
              outlined
              hide-details="auto"
              v-model="unidad.disponibilidad"
            ></v-text-field>
            <v-text-field
              label="Años (edad aprox.)"
              outlined
              hide-details="auto"
              v-model="unidad.años"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" class="pt-0">
          <v-textarea
            style="width:100%"
            label="Observaciones"
            outlined
            hide-details=""
            v-model="unidad.observaciones"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row style="border-left: 3px solid var(--v-success-base);border-top: 3px solid var(--v-success-base);border-right: 3px solid var(--v-success-base)">
        <v-col cols="12" class="py-0">
          <h1>DATOS TÉCNICOS</h1>
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                append-icon="mdi-calendar"
                :value="unidad.inicio ? new Date(unidad.inicio).toLocaleDateString('esp',{ month: 'short', year: 'numeric' }).toLocaleUpperCase() : 'No especificado'"
                readonly
                label="Inicio laboral"
                outlined
                hide-details=""
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              v-model="unidad.inicio"
              no-title
              scrollable
              @change="menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" class="pb-0">
          <v-text-field
            label="Edad"
            outlined
            hide-details=""
            v-model="unidad.edad"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pb-0">
          <v-checkbox
            style="transform:scale(1.5,1.5);max-width: 0% !important"
            label="Coche"
            hide-details=""
            v-model="unidad.coche"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="4" class="pb-0">
          <v-checkbox
            style="transform:scale(1.5,1.5);max-width: 0% !important"
            label="Montajes"
            hide-details=""
            v-model="unidad.montaje"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6">
          <v-row class="pa-0 ma-0 align-center justify-space-between">
            <v-col cols="12" md="4" class="py-0">
              <h3 style="white-space:nowrap !important">Desempeño</h3>
            </v-col>
            <v-col class="text-right py-0" cols="12" md="8">
              <span>
                <v-btn 
                  small
                  fab
                  plain 
                  @click.stop="unidad.desempeno = i" 
                  class="star" 
                  v-for="i in 5" 
                  :key="i"
                  @mouseover="hoverDes = i" 
                  @mouseleave="hoverDes = null" 
                  :style="'color: ' + (hoverDes ? (i <= hoverDes ? 'var(--v-primary-base)' : 'lightgray') : ((unidad.desempeno || 0) < i ? 'lightgray' : 'var(--v-primary-base)'))">
                  <v-icon large>{{ hoverDes ? (i <= hoverDes ? 'mdi-star' : 'mdi-star-outline') : ((unidad.desempeno || 0) < i ? 'mdi-star-outline' : 'mdi-star')}}</v-icon>
                </v-btn> 
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row class="pa-0 ma-0 align-center justify-space-between">
            <v-col cols="12" md="4" class="py-0">
              <h3 style="white-space:nowrap !important">Compañerismo</h3>
            </v-col>
            <v-col class="text-right py-0" cols="12" md="8">
              <span>
                <v-btn 
                  small
                  fab
                  plain 
                  @click.stop="unidad.companerismo = i" 
                  class="star" 
                  v-for="i in 5" 
                  :key="i"
                  @mouseover="hoverCom = i" 
                  @mouseleave="hoverCom = null" 
                  :style="'color: ' + (hoverCom ? (i <= hoverCom ? 'var(--v-primary-base)' : 'lightgray') : ((unidad.companerismo || 0) < i ? 'lightgray' : 'var(--v-primary-base)'))">
                  <v-icon large>{{ hoverCom ? (i <= hoverCom ? 'mdi-star' : 'mdi-star-outline') : ((unidad.companerismo || 0) < i ? 'mdi-star-outline' : 'mdi-star')}}</v-icon>
                </v-btn> 
              </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <template v-if="(!!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['CONTROL_HORAS']) || !!($store.getters.getTokenPayload.permisos & $store.getters.getPermisos['EVENTOS']))">
        <v-row style="border-left: 3px solid var(--v-info-base);border-top: 3px solid var(--v-info-base);border-right: 3px solid var(--v-info-base)" :class="active ? 'active' : 'activent'">
          <v-col cols="12" class="py-0">
            <v-row class="pa-0 ms-1 mt-1">
              <h1>DATOS LABORALES</h1>
              <v-spacer></v-spacer>
              <v-btn small @click.stop="active = !active" icon fab><v-icon>{{ active ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon></v-btn>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="P. Evento"
              outlined
              hide-details="auto"
              v-model="unidad.pEvento"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="P. Evento Top"
              outlined
              hide-details="auto"
              v-model="unidad.pEventoTop"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="P. Montaje"
              outlined
              hide-details="auto"
              v-model="unidad.pMontaje"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              label="Contratación"
              outlined
              hide-details="auto"
              :items="['CONTRATO', 'LLAMAMIENTO', 'ALTA/BAJA']"
              v-model="unidad.contratacion"
            ></v-select>
          </v-col>
        </v-row>
      </template>
    </v-form>

    <v-card-actions class="mt-4">
      <v-spacer></v-spacer>
      <v-btn color="error" @click.stop="$emit('close')">Cancelar</v-btn>
      <v-btn color="primary" @click.stop="guardar">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
    UnidadEquipo: () => import("../components/UnidadEquipo.vue"),
  },
  // computed: {
  //   unidad: {
  //     get() {
  //       return this.value;
  //     },
  //     set(v) {
  //       this.$emit("input", v);
  //     },
  //   },
  // },
  data() {
    return {
      hoverDes: null,
      hoverCom: null,
      menu:false,
      active:false,
      pfp: '',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      categorias: [],
			unidad: {...this.value}
    };
  },
  methods: {
    addPfp(){
      this.$refs.pfp.$refs.input.click()
    },
    processPfp(img){
      if(img.type.match(/image.*/)){
        var reader = new FileReader();
        const self = this
        reader.onload = function (readerEvent) {
            var image = new Image();
            image.onload = function (imageEvent) {

                // Resize the image
                var canvas = document.createElement('canvas'),
                    max_size = 256,
                    width = image.width,
                    height = image.height;
                if (width > height) {
                    if (width > max_size) {
                        height *= max_size / width;
                        width = max_size;
                    }
                } else {
                    if (height > max_size) {
                        width *= max_size / height;
                        height = max_size;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                self.$set(self.unidad,'pfp',canvas.toDataURL('image/jpeg').split(';base64,')[1]);
            }
            image.src = readerEvent.target.result;
        }
        reader.readAsDataURL(img);
      }
      else{
        alert("Formato de archivo incorrecto")
      }
    },
    async getCategorias() {
      const { data } = await axios({
        method: "GET",
        url: "/categorias_equipo",
      });

      this.categorias = data;
    },
		guardar() {
      this.unidad.inicio = (this.unidad.inicio || '')
      this.unidad.desempeno = (this.unidad.desempeno || 0)
      this.unidad.edad = (this.unidad.edad || 0)
      this.unidad.companerismo = (this.unidad.companerismo || 0)
      this.unidad.inicialesApellidos = (this.unidad.inicialesApellidos || '')
      this.unidad.disponibilidad = Number(this.unidad.disponibilidad || 0)
      this.unidad.experiencia = Number(this.unidad.experiencia || 0)
      this.unidad.prioridad = Number(this.unidad.prioridad || 0)
      this.unidad.contratacion = (this.unidad.contratacion || '-')
      this.unidad.pEvento = (this.unidad.pEvento || 0)
      this.unidad.pEventoTop = (this.unidad.pEventoTop || 0)
      this.unidad.pMontaje = (this.unidad.pMontaje || 0)
      this.unidad.observaciones = (this.unidad.observaciones || '')
      this.unidad.pfp = (this.unidad.pfp || '')
      this.unidad.coche = (this.unidad.coche || false)
      this.unidad.montaje = (this.unidad.montaje || false)
			const data = axios({
				method: this.unidad.idUnidadEquipo ? 'PUT' : 'POST',
				url: '/unidades_equipo' + ( this.unidad.idUnidadEquipo ? ('/' + this.unidad.idUnidadEquipo) : '' ), 
				data: this.unidad
			}).then(() => {
				this.$emit('close')
				this.$emit('reload')
			}).catch(err => {
				console.error(err)
				this.$emit('snack', 'No se han podido guardar los datos')
			})
		},
  },
  mounted() {
    this.getCategorias();
  },
};
</script>

<style>
.star:hover{
  cursor:pointer;
}
.star.v-btn--active::before {
  opacity: 0 !important;
}

.activent{
  max-height:50px !important;
  overflow: hidden
}
.active{
  max-height:1000px !important;
  overflow: hidden
}
</style>